import { Col, Collapse, Input, Row, Select } from 'antd';
import {
    InfoCircleOutlined
  } from '@ant-design/icons';

import DeleteButton from './delete-button';

import { __InputValue } from 'graphql';


const ShellPythonTask = (props:any) => {
  const { item, 
    handleChange, 
    handleDependsOnSelectChange, deleteTask} = props;
const { Panel } = Collapse;
const { Option } = Select;
const handleArgumentChange = (value:string | string[]) => {
    handleChange(value , 'args');
}
console.log('inside the java task component, item:' + item)
    return (
      <Panel {...props}>
          <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                  <label style={{marginRight: '6px'}}>Name</label>
                  <InfoCircleOutlined />
              </Col>
              <Col className="gutter-row" span={12}>
                  <Input placeholder="bno_aprtwo"  value={item.name} onChange={(ev:any) => handleChange(ev.target.value,'name')} style={{marginTop: '0'}} />
              </Col>
          </Row>
      
          <Row gutter={16} style={{marginTop:'20px'}} align="middle">
              <Col className="gutter-row" span={6}>
                  <label style={{marginRight: '6px'}}>Command</label>
                  <InfoCircleOutlined />
              </Col>
              <Col className="gutter-row" span={10}>
                  <Input type="text" placeholder='chmod' value={item.type === 'BashTask' ? item.command : item.script} onChange={(ev:any) => handleChange(ev.target.value,`${item.type === 'BashTask' ? 'command' : 'script'}`)} />
              </Col>
          </Row>
          
          {/* Conditionally render the Virtual Env Path row */}
            {item.type === 'PythonTask' && (
                <Row gutter={16} style={{ marginTop: '20px' }} align="middle">
                <Col className="gutter-row" span={6}>
                    <label style={{ marginRight: '6px' }}>Virtual Env Path</label>
                    <InfoCircleOutlined />
                </Col>
                <Col className="gutter-row" span={10}>
                    <Input
                    type="text"
                    placeholder=".venv"
                    value={item.venvPath}
                    onChange={(ev: any) => handleChange(ev.target.value, 'venvPath')}
                    />
                </Col>
                </Row>
            )}
          
          <Row gutter={16} style={{marginTop:'20px'}} align="middle">
              <Col className='gutter-row' span={6}>
                  <span style={{marginRight: '6px'}}> Arguments</span>
                  <InfoCircleOutlined />
              </Col>
              <Col className='gutter-row' span={10}>
              <Select mode="tags"
                  placeholder="Please enter multiple arguments"
                  style={{ width: '100%' }}
                  defaultValue={item.args}
                  onChange={handleArgumentChange}
              />
          
              </Col>
          </Row>
          {(props.definitionData.filter((data:any) => data.id !== item.id && data.name.length > 0)).length > 0 && (
              <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                  <Col className='gutter-row' span={6}>
                      <span style={{marginRight: '6px'}}>Dependent Task</span>
                      <InfoCircleOutlined />
                  </Col>
                  
                  <Col className='gutter-row' span={10}>
                      <Select
                          mode="multiple"
                          placeholder="Please select multiple"
                      
                          onChange={handleDependsOnSelectChange}
                          value={item.dependsOn}
                          style={{ width: '100%' }}
                          
                      >
                          {props.definitionData.filter((data:any) => data.id !== item.id).map((value:any) => <Option key={value.id}>{value.name}</Option>)}
                      </Select>
                  </Col>
              </Row>
          )}
  <br/>
  <br/>
  <DeleteButton deleteTask={deleteTask} />
  
</Panel>

    );
    
}

export default ShellPythonTask;
