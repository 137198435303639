import styled from "@emotion/styled";
import { notification, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { EXEC_STATUS } from "..";
import { NotificationsCard } from "../styled";

interface EmailNotificationProps {
    emailNotification?: any;
    removeEmailNotification?: () => void;
    updateEmailNotification?: (updatedEmail: any) => void;
}
export const EmailNotification: React.FC<EmailNotificationProps> = ({ emailNotification, removeEmailNotification, updateEmailNotification }) => {

    let execStatuses: EXEC_STATUS[] = emailNotification ? emailNotification?.execStatuses : [];
    let recipients: string[] = emailNotification ? emailNotification?.emailWFNotification?.recipients : [];

    const onEventSelect = (e: EXEC_STATUS) => {
        execStatuses = [...execStatuses, e];
        update();
    }

    const onEventDeSelect = (e: EXEC_STATUS) => {
        execStatuses = execStatuses.filter(es => es !== e)
        update();
    }

    const update = () => {
        if (updateEmailNotification)
            updateEmailNotification({ channelType: "EMAIL", execStatuses: execStatuses, emailWFNotification: { recipients: recipients } });
    }

    const onRecipientSelect = (recipient: string) => {
        const email_patten = /^[^@]+@[^@]+\.[^@]+$/
        if (email_patten.test(recipient)) {
            recipients = [...recipients, recipient];
            update();
        } else {
            notification.warning({
                message: 'Warning!',
                description: 'Please add valid email address'
            })
        }
    }

    const onRecipientDeselect = (recipient: string) => {
        recipients = recipients.filter(email => email !== recipient)
        update();
    }


    return (
        <NotificationsCard >
            <StyledDelete onClick={() => removeEmailNotification && removeEmailNotification()} />
            <div>
                <span>Select Event</span>
                <StyledSelect onSelect={(e: any) => onEventSelect(e)}
                    onDeselect={(e: any) => onEventDeSelect(e)}
                    mode="multiple"
                    tokenSeparators={[',']}
                    value={execStatuses}
                >
                    <Select.Option value="WFEXECSTATUS_STARTED" key="1">
                        Workflow started
                    </Select.Option>
                    <Select.Option value="WFEXECSTATUS_FAILED" key="2">
                        Workflow failed
                    </Select.Option>
                    <Select.Option value="WFEXECSTATUS_SUCCESS" key="3">
                        Workflow succeeded
                    </Select.Option>
                    <Select.Option value="WFEXECSTATUS_EXPIRED" key="4">
                        Workflow Expired
                    </Select.Option>
                </StyledSelect>
            </div>
            <div>
                <span>Select Email</span>
                <StyledSelect value={recipients}
                    onSelect={(e: any) => onRecipientSelect(e)}
                    onDeselect={(e: any) => onRecipientDeselect(e)}
                    mode="tags"
                    tokenSeparators={[',']}>
                </StyledSelect>
            </div>
        </NotificationsCard >
    )
}


const StyledSelect = styled(Select)`
width: 100%;
margin-top: 8px;
margin-bottom: 8px;
`;

const StyledDelete = styled(DeleteOutlined)`
float: right;
color: red;
`;