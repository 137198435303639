import { useState } from "react";
import { notification } from "antd";

import { gql, useMutation } from "@apollo/client";

import "antd/dist/antd.min.css";
import "./index.css";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

const CREATE_WORKSPACE = gql`
  mutation CreateWorkspace($name: String!, $description: String) {
    createWorkspace(name: $name, description: $description) {
      success
      result {
        ... on Workspace {
          workspaceId
        }
      }
      errors {
        errorCode
        errorMessage
      }
    }
  }
`;

type DrawerState = "visible" | "hidden";

// @ts-ignore
export const CreateWorkspaceForm = (props) => {
  const [form] = Form.useForm();

  const [createWorkspaceGQL, { data }] = useMutation(CREATE_WORKSPACE);

  const [drawerState, setDrawerState] = useState<DrawerState>("hidden");
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [isValidWorkspaceName, setIsValidWorkspaceName] = useState<boolean>(false);
  const [isValidWorkspaceDescription, setIsValidWorkspaceDescription] = useState<boolean>(false);

  const onClose = () => {
    setDrawerState("hidden");
  };

  const onFinish = () => {
    //Do not submit if workspace name is not valid
    if(!isValidWorkspaceName || !isValidWorkspaceDescription) {
      return;
    }
    setLoadingButton(true);
    createWorkspaceGQL({
      variables: {
        name: form.getFieldValue("name"),
        description: form.getFieldValue("description"),
      },
    })
      .then(() => {
        notification["success"]({
          message: "New Workspace created",
          description: "Your New Workspace is Ready!",
        });
        setLoadingButton(false);
        form.resetFields();
        onClose();
        props.refetch();
      })
      .catch(() => {
        notification["error"]({
          message: "Some Error Occured",
          description: "Some error occured while creating new Workspace!",
        });
        form.resetFields();
        setLoadingButton(false);
      });
  };

  const showDrawer = () => {
    setDrawerState("visible");
  };

  const toggleDrawer = () => {
    switch (drawerState) {
      case "visible":
        setDrawerState("hidden");
        break;
      case "hidden":
        setDrawerState("visible");
        break;
    }
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        <PlusOutlined /> New Workspace
      </Button>
      <Drawer
        title="Create a new workspace"
        width={720}
        onClose={onClose}
        visible={drawerState === "visible"}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={onFinish} type="primary" htmlType="submit" loading={loadingButton}>
              Create
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Workspace name is required" }, {
                  message: 'Please enter a valid workspace name. Only lower case letters, numbers and underscores are allowed',
                  validator: (_, value) =>{
                    const re = new RegExp('^[a-z0-9_]+$');
                    if( re.test(value)) {
                      setIsValidWorkspaceName(true);
                      return Promise.resolve();
                    } else {
                      setIsValidWorkspaceName(false);
                      return Promise.reject('Incorrect format')
                    }
                  }
                }]}
              >
                <Input placeholder="Please enter workspace name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "please enter a description for this workspace",
                  },{
                    message: 'Please enter a non empty description',
                    validator: (_, value) =>{
                      const re = new RegExp('^.+$');
                      if( re.test(value)) {
                        setIsValidWorkspaceDescription(true);
                        return Promise.resolve();
                      } else {
                        setIsValidWorkspaceDescription(false);
                        return Promise.reject('Incorrect format')
                      }
                    }
                  }
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="please enter description"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
