import React, { FC, useState } from "react";
import { Badge } from "antd";


const WFRunsMinified: FC<{ nodes: any }> = ({ nodes }) => {
	
	const statusColors :any = {
        WFEXECSTATUS_INVALID: "red",
        WFEXECSTATUS_REQUESTED: "yellow",
        WFEXECSTATUS_STARTED: "yellow",
        WFEXECSTATUS_INPROGRESS: "yellow",
        WFEXECSTATUS_SUCCESS: "green",
        WFEXECSTATUS_FAILED: "red",
        WFEXECSTATUS_EXPIRED: "orange",
      };
      const statusText: any = {
        WFEXECSTATUS_INVALID: "Invalid",
        WFEXECSTATUS_REQUESTED: "Requested",
        WFEXECSTATUS_STARTED: "Started",
        WFEXECSTATUS_INPROGRESS: "In Progress",
        WFEXECSTATUS_SUCCESS: "Success",
        WFEXECSTATUS_FAILED: "Failed",
        WFEXECSTATUS_EXPIRED: "Expired",
      };
      const execTypeText: any = {
        WFEXECTYPE_SCHEDULED: "Scheduled",
        WFEXECTYPE_ON_DEMAND: "On Demand",
        WFEXECTYPE_WF_TRIGGERED: "WF Triggered",
      };
      console.log("Inside WFRunsMinified, nodes = " + nodes)

    const generateLabel = (node:any) => {
        return statusText[node.execStatus] + ", " + execTypeText[node.execType] + ": " + new Date(node.scheduledTime).toLocaleString();
    }

	return (
		<div>
            
			<div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {nodes?.map((node:any, index:any) => (
        <div key={node.runId} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {/* Badge for status */}
          <a href={`workflows/${node.workflowId}/runhistory?wfRunId=${node.runId}`}>
            <Badge color={statusColors[node.execStatus] || "default"} text={generateLabel(node)} />
             
          </a>
        </div>
      ))}
    </div>
		</div>
	);
};

export default WFRunsMinified;