import React, { FC, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import {
  Form,
  Drawer,
  Col,
  Row,
  PageHeader,
  Table,
  Spin,
  Menu,
  Dropdown,
  Modal,
  notification,
  Input,
  Button,
  Empty,
  Space
} from "antd";
import { Heading, Container } from "./styled";
import { CreateWorkflowForm } from "../../components/create-workflow";
import { Link, useLocation, useNavigate  } from "react-router-dom";
import { DownOutlined, DeleteOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./index.css";
import { wfStatusMap } from "../../assets/dictionary/wf-status";
import { SearchWorkspace } from "../../components/search-workspace";
import { logout } from "../../helpers/util";
import _, { runInContext } from "lodash";
import WFRunsMinified from "./wfRuns"
import FormItem from 'antd/lib/form/FormItem';
const GET_WORKFLOW_INPUTS = gql`
  query($workflowId:String!) {
    getWorkflowById(id: $workflowId) {
        definition{
          inputs{
            name
            defaultValue
          }
        }
    }
  }
`;
const GET_WORKFLOWS = gql`
  query($workspaceId: String, $search: String,$after: String,$before: String) {
    getWorkflows(search: $search, workspaceId: $workspaceId, first: 5,after: $after,before: $before) {
      pageInfo {
        prevPageCursor
        nextPageCursor
      }
      nodes {
        ... on Workflow {
          id
          name
          description
          status
          wfRunCondition {
            wfRunConditionType
            ... on WFScheduledCronRunCondition {
              wfSchedule {
                cron
                cronType
              }
              
            }
            ... on WFCompletionRunCondition {
              workflowId
              workflow {
                name
              }
            }
          }
          taskExecutor {
            taskExecutorId
            name
          }
          lastUpdated
          wfRuns(first: 5) {
            nodes {
              ... on WFRun {
                runId
                workflowId
                execType
                execStatus
                scheduledTime
              }
              
            }
          }
        }
      }
    }
  }
`;

const RUN_WORKFLOW = gql`
  mutation runWorkflowNow($workflowId: String!, $inputVariables: [InputKeyValuePair], $description: String) {
    runWorkflowNow(workflowId: $workflowId, inputs: $inputVariables, description: $description) {
      result {
        ... on WFRun {
          id
          runId
          execType    
        }
        
      }
      success
      errors {
        errorCode
        errorMessage
      }
    }
  }
`;

const DELETE_WORKFLOW = gql`
	mutation deleteWorkflows(
		$ids: [String!]!
	) {
		deleteWorkflows(
			ids: $ids
		) {
			success
			errors {
				errorCode
				errorMessage
			}
		}
	}
`;

type DrawerState = "visible" | "hidden";

const RunWorkflowNowDrawer: FC<{ workflow: any, onClose: any, onRunNow: any, runNowForm: any }> = ( {workflow, onClose, onRunNow, runNowForm}) => {
  
  const { loading, error, data } = useQuery(GET_WORKFLOW_INPUTS, {
		variables: { workflowId: workflow.id },
	});
	

	if (loading)
		return (
			<div className="center-loader">
				<Spin size="large" />
			</div>
		);
	if (error)
		return (
			<p>
				Could not fetch inputs required for this workflow. Please close this pane and try again.
			</p>
		);
  const inputs = _.map(data.getWorkflowById.definition?.inputs, (input: any, index) => {
			
    return (
      
        <Row gutter={16} key={_.get(input,'name')}>
          <Col className="gutter-row" span={12}>
            <Input value={input.name} disabled={true} />
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item name={_.get(input,'name')} initialValue={_.get(input,'defaultValue')}
                rules={[{ required: true, message: "value required" }]}>
            <Input name={_.get(input,'name')} value={_.get(input,'defaultValue')}/>
            </Form.Item>
          </Col>
        </Row>

    );
  });
  const onFinish = (values:any) => {
    console.log('Received values of form:', values);
    const desc = _.get(values, 'description');
    const inputVariables = _.map(data.getWorkflowById.definition?.inputs, (input: any, index) => {
      return {name: input.name, value: _.get(values, input.name)}
    });
    onRunNow(workflow.id, inputVariables, desc);
  };
	return (
		<div>
      <Form form={runNowForm} layout="vertical" onFinish={onFinish} >
        { _.size(inputs) == 0? 
          <div> <p>This workflow does not require any parameters. Do you want to run this workflow now?</p> </div>
        :
          <div>
            <p> This workflow requires the following parameters. Set the values if you wish to override the defaults before running.</p>
            
            <div className="KeyValueContainer" style={{marginBottom: "20px"}}>
                {inputs}
            </div>
          </div>
        }
        
        <div style={{
                textAlign: "right",
              }}
            >
              <br />
              <Row gutter={16}>
          <Col className="gutter-row" span={24}>
          <Form.Item name="description" label="Description"
                rules={[{ required: true, message: "Please enter a description" },
                { max: 150, message: 'Description must be max 150 characters.' }]}>
                <Input name="description" 
                 placeholder='Enter some text to provide additional details about this workflow run.'/>
              </Form.Item>
          </Col>
          </Row>
              
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button  htmlType="submit" type="primary">
                Run now
              </Button>
        </div>
      </Form>
		</div>
	);
};

export const WorkflowsPage: FC<{}> = () => {
  const [runLoading, setRunLoading] = useState<boolean>(false);
  const [afterPage, setAfterPage] = useState<any>(null)
  const [beforePage, setBeforePage] = useState<any>(null)
  const [runWorkflow, { data: runResponse }] = useMutation(RUN_WORKFLOW);
  const queryParams = new URLSearchParams(useLocation().search);
  const [filter, setFilter] = useState<string>(
    queryParams.get("workspaceId") || ""
  );

  const [flowSearchFilter, setFlowSearchFilter] = useState<string>(
    queryParams.get("search") || ""
  );

  
  const [runNowForm] = Form.useForm();
  const [deleteWorkflow, {error:deleteError, data:deleteData,loading:deleteLoading}]  = useMutation(DELETE_WORKFLOW);
  const [currentWorkflowToRun, setCurrentWorkflowToRun] = useState("");
  const [runNowDrawerState, setRunNowDrawerState] = useState<DrawerState>("hidden");

  const onRunNowDrawerClose = () => {
		setRunNowDrawerState("hidden");
	};

	const showRunNowDrawer = (workflow:any) => {
    setCurrentWorkflowToRun(workflow);
		setRunNowDrawerState("visible");
	};

	const showRunNowDrawerWrapper = (workflow: any) => {
		showRunNowDrawer(workflow);
	};
  const onClose = () => {
    runNowForm.resetFields();
		setRunNowDrawerState("hidden");
	};

  const deleteWF =(row:any) =>{
	
    Modal.confirm({
      title: 'Are you sure?',
      content: "Delete this workflow including all run history? This action is not reversible.",
      okText:'Delete',
      onOk() {
        deleteWorkflow({variables:{
          ids:[_.get(row,'id')]
        }}).then((res) => {
          
          if (_.get(res,'data.deleteWorkflow.errors')==null) {
            notification["success"]({
            description:"Workflow deleted successfully",
            message: "Success",
            });
          
            refetch();
          }else if(_.get(res,'data.deleteWorkflow.errors[0].errorMessage') ){
            notification.error({
                  message: 'Error',
                  description: _.get(res,'data.deleteWorkflow.errors[0].errorMessage'),
                });
            }
          
        })
        .catch((err) => {
          notification["error"]({
            message: "An unknown error occured",
            description: err?.message,
          });
        });},
      cancelButtonProps: {
        disabled: false
      },
      // confirmLoading:true,
      cancelText: 'Cancel'
    })
  
  }

  const navigate = useNavigate ();

  const handleLogOut = () => {
    logout();
  };

  let loading;

  const {
    loading: loadingWorkflows,
    error: errorWorkflows,
    data: dataWorkflows,
    refetch,
  } = useQuery(GET_WORKFLOWS, {
    variables: { workspaceId: filter, search: flowSearchFilter, after:afterPage,before:beforePage },
  });

  if (loadingWorkflows || deleteLoading) {
    if (!flowSearchFilter.length) {
      return (
        <div className="center-loader">
          <Spin size="large" />
        </div>
      );
    } else {
      loading = (
        <div className="center-loader">
          <Spin size="large" />
        </div>
      );
    }
  }

  if (errorWorkflows)
    return (
      <p>
        Your session has expired, please <b onClick={handleLogOut}>login</b>{" "}
        again
      </p>
    );

  const workflows = dataWorkflows?.getWorkflows?.nodes?.map((item: any) => {
    return {
      name: item?.name,
      description: item?.description,
      lastUpdated: item?.lastUpdated,
      executor: item?.taskExecutor,
      wfRunCondition: item?.wfRunCondition,
      id: item?.id,
      status: item?.status,
      wfRuns: item?.wfRuns?.nodes
    };
  });

  const startRun = (workflowId: any, 
    inputVariables: {name: String, value: String},
    description: String) => {
    
    setRunLoading(true);
    runWorkflow({
      variables: {
        workflowId: workflowId,
        inputVariables: inputVariables,
        description: description
      },
    })
      .then((res) => {
        const { success, result, errors } = res?.data?.runWorkflowNow;
        if (result) {
          onClose();
          notification["success"]({
            message: "Successful",
            description: "Workflow run has been requested!",
          });
        } else {
          notification["error"]({
            message: "An Error Occured",
            description: errors[0]?.errorMessage,
          });
        }
        setRunLoading(false);
      })
      .catch(() => {
        notification["error"]({
          message: "Some Error Occured",
          description: "Some error occured while running!",
        });

        setRunLoading(false);
      });
  };


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Executor",
      dataIndex: "executor",
    },
    {
      title: "Trigger Type",
      dataIndex: "wfRunCondition",
      render: (wfRunCondition: any, row: any) => (      
        <>
          {wfRunCondition?.wfRunConditionType == "WORKFLOW_COMPLETE"?
          <>
            <span>Runs after </span><Link className="details-button" to={`/workflows/${wfRunCondition.workflowId}`}>{wfRunCondition.workflow?.name}</Link> 
            </> :
           wfRunCondition?.wfRunConditionType == "SCHEDULED_CRON"?
           "cron: " + wfRunCondition.wfSchedule?.cron : "Manually Run"}

      </>
          
      ),
    },
    // {
    //   title: "Last Updated",
    //   dataIndex: "lastUpdated",
    // },
    {
      title: "Last 5 Runs",
      dataIndex: "wfRuns",
      render: (text:any, row:any) => (
          <Space size="middle">
              <div><WFRunsMinified nodes={row.wfRuns} /></div>
          </Space>
      )
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (data: string, row:any) => (
        <Dropdown
          overlay={
            <Menu>
              
              <Menu.Item>
                <span data-id={data} onClick={() => showRunNowDrawerWrapper(row)}>
                  Run now
                </span>
              </Menu.Item>
              <Menu.Item>
                <Link className="details-button" to={`/workflows/${data}`}>
                  View Details
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link className="details-button" to={`/workflows/${data}/runhistory`}>
                  View History
                </Link>
              </Menu.Item>
            </Menu>
          }
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            View Details <DownOutlined />
          </a>
        </Dropdown>
      ),
    },
		{
      title: "Action",
      dataIndex: "id",
      render: (text:any, row:any) => (
          <Space size="middle">
              <div><DeleteOutlined onClick={()=>deleteWF(row)} /></div>
          </Space>
      )
    }
  ];
  const onChangeAfterBeforePage=(type:any)=>{
    
    console.log(_.get(dataWorkflows,'getWorkflows.pageInfo'))
    
    if(_.get(dataWorkflows,'getWorkflows.pageInfo')) {
      if (type=='next' &&_.get(dataWorkflows,'getWorkflows.pageInfo.nextPageCursor')){
        setBeforePage(null)
        setAfterPage(_.get(dataWorkflows,'getWorkflows.pageInfo.nextPageCursor'))
      }else if(type=='prev' &&_.get(dataWorkflows,'getWorkflows.pageInfo.prevPageCursor')){
        setAfterPage(null)
        setBeforePage(_.get(dataWorkflows,'getWorkflows.pageInfo.prevPageCursor'))
      } 
    }
  }
  

  const dataforTable: any[] = [];

  for (let i = 0; i < workflows?.length; i++) {
    dataforTable.push({
      key: i,
      name: workflows[i].name,
      description: workflows[i].description,
      status: wfStatusMap(workflows[i].status),
      executor: workflows[i].executor?.name,
      wfRunCondition: workflows[i].wfRunCondition,
      lastUpdated: new Date(workflows[i].lastUpdated).toLocaleString(),
      wfRuns: workflows[i].wfRuns,
      id: workflows[i].id,

    });
  }

  const handleChange = (value: any) => {
    setFilter(value);
    const params = new URLSearchParams();
    params.append("workspaceId", value);
    params.append("search", queryParams.get("search") || "");
    navigate({ search: params.toString() });
  };

  const onFlowSearch = (e: any) => {
    setFlowSearchFilter(e.target.value);
    const params = new URLSearchParams();
    params.append("search", e.target.value);
    params.append("workspaceId", queryParams.get("workspaceId") || "");
    navigate({ search: params.toString() });
  };
  const { Search } = Input;

  return (
    <>
      {runLoading && (
        <div className="fixed-center-loader">
          <Spin size="large" />
        </div>
      )}
      <Container>
        <Heading>Work Flows</Heading>
        <CreateWorkflowForm refetch={refetch} />,
      </Container>
      <Container>
        <SearchWorkspace handleChange={handleChange} />
        {/* <Search
          placeholder="Search"
          className="default-search"
          onChange={onFlowSearch}
        /> */}
      </Container>
      <br />
      <br />
      {loading}
      {
				!_.isEmpty(dataforTable) ? 
				(
          <Table
            pagination={false}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            columns={columns}
            dataSource={dataforTable}
          />
        ): 
				(   <Empty />)
      }
      <br />
      { _.isArray(workflows)==true && _.size(workflows)>0 && (_.get(dataWorkflows,'getWorkflows.pageInfo.prevPageCursor') || _.get(dataWorkflows,'getWorkflows.pageInfo.nextPageCursor')) && <div className="row" style={{textAlign:'center'}}>
						<Button icon={<LeftOutlined style={{marginLeft:'3px'}} />}  disabled={_.get(dataWorkflows,'getWorkflows.pageInfo.prevPageCursor')?false:true} style={{marginRight:"25px"}} onClick={()=>onChangeAfterBeforePage('prev')}>Previous</Button>
						<Button  disabled={_.get(dataWorkflows,'getWorkflows.pageInfo.nextPageCursor')?false:true} onClick={()=>onChangeAfterBeforePage('next')}>
						Next <RightOutlined style={{marginLeft:'3px'}} /></Button>
					</div>}
          <Drawer
				title="Run Workflow"
				width={720}
				onClose={onClose}
				visible={runNowDrawerState == "visible"}
				bodyStyle={{ paddingBottom: 80 }}
			>
				<RunWorkflowNowDrawer 
        workflow={currentWorkflowToRun} 
        onClose={onClose} 
        onRunNow={startRun}
        runNowForm={runNowForm}/>
			</Drawer>
    </>
  );
};