import React, { useState } from "react";

import { gql, useMutation, useQuery } from "@apollo/client";

import "antd/dist/antd.min.css";
import "./index.css";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  notification,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const CREATE_TASKEXECUTOR = gql`
  mutation createTaskExecutor(
    $name: String!
    $description: String
    $workspaceId: String!
  ) {
    createTaskExecutor(
      name: $name
      description: $description
      workspaceId: $workspaceId
    ) {
      success
      errors {
        errorCode
        errorMessage
      }
      result {
        ... on TaskExecutor {
          taskExecutorId
          name
          description
        }
      }
    }
  }
`;

const GET_MY_WORKSPACES = gql`
  query USER_WORKSPACES($search: String) {
    getWorkspaces(search: $search, first: 10) {
      pageInfo {
        prevPageCursor
        nextPageCursor
      }
      nodes {
        id
        ... on Workspace {
          workspaceId
          name
        }
      }
    }
  }
`;

type DrawerState = "visible" | "hidden";

// @ts-ignore
export const CreateTaskExecutorForm = (props) => {
  const [form] = Form.useForm();
  const { refetch } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");
  const [createTaskExecutorGQL, { data }] = useMutation(CREATE_TASKEXECUTOR);
  const { data: dataWorkspace } = useQuery(GET_MY_WORKSPACES, {
    variables: { search: searchFilter },
  });

  const [drawerState, setDrawerState] = useState<DrawerState>("hidden");
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [isValidTexName, setIsValidTexName] = useState<boolean>(false);
  const [isValidTexDesc, setIsValidTexDesc] = useState<boolean>(false);

  const onClose = () => {
    setDrawerState("hidden");
  };

  const onFinish = () => {
    //Do not submit if tex name is not valid
    if(!isValidTexName || !isValidTexDesc) {
      return;
    }

    setLoadingButton(true);
    createTaskExecutorGQL({
      variables: {
        name: form.getFieldValue("name"),
        description: form.getFieldValue("description"),
        workspaceId: form.getFieldValue("workspaceId"),
      },
    })
      .then(() => {
        notification["success"]({
          message: "New Task Executor created",
          description: "Your New Task Executor is Ready!",
        });
        setLoadingButton(false);
        form.resetFields();
        onClose();
        refetch();
      })
      .catch(() => {
        notification["error"]({
          message: "Some Error Occured",
          description: "Some error occured while creating new Task Executor!",
        });
        setLoadingButton(false);
        form.resetFields();
      });
  };

  const showDrawer = () => {
    setDrawerState("visible");
  };

  const onWsSearch = (val: string) => {
    setSearchFilter(val);
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        <PlusOutlined /> New Task Executor
      </Button>
      <Drawer
        title="Create a new Task-Executor"
        width={720}
        onClose={onClose}
        visible={drawerState === "visible"}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={onFinish} type="primary" loading={loadingButton}>
              Create
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Task Executor name is required" }, {
                  message: 'Please enter a valid Task-Executor name. Only lower case letters, numbers and underscores are allowed',
                  validator: (_, value) =>{
                    const re = new RegExp('^[a-z0-9_]+$');
                    if( re.test(value)) {
                      setIsValidTexName(true);
                      return Promise.resolve();
                    } else {
                      setIsValidTexName(false);
                      return Promise.reject('Incorrect format')
                    }
                  }
                }]}
              >
                <Input placeholder="Please enter Task Executor name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="workspaceId"
                label="Work Space"
                rules={[{ required: true, message: "Work Space Name" }]}
              >
                <Select
                  showSearch
                  placeholder="Please enter the associated Work Space"
                  onSearch={onWsSearch}
                  filterOption={(input, option:any) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataWorkspace?.getWorkspaces?.nodes?.map(
                    (workspace: { id: string; name: string }) => (
                      <Option value={workspace.id} key={workspace.id}>
                        {workspace.name}
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter a description for this Task Executor",
                  }, {
                    message: 'Please enter a valid Task-Executor Description.',
                    validator: (_, value) =>{
                      const re = new RegExp('^.+$');
                      if( re.test(value)) {
                        setIsValidTexDesc(true);
                        return Promise.resolve();
                      } else {
                        setIsValidTexDesc(false);
                        return Promise.reject('Incorrect format')
                      }
                    }
                  }
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="please enter description"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
