import { Button, Col, Collapse, Dropdown, Input, Menu, Row, Select } from 'antd';
import PythonIcon from '../../assets/python-icon';
import ShellIcon from '../../assets/shell-icon';
import JavaIcon from '../../assets/java-icon';
import PlusCircleOutline from "@ant-design/icons";
import { useState } from 'react';
import {
    InfoCircleOutlined
  } from '@ant-design/icons';
import InputIcon from '../../assets/images/enter.png'
import DeleteButton from './delete-button';
import { __InputValue } from 'graphql';
import JavaTask from './java-task';
import ShellPythonTask from './shell-python-task';
import DockerIcon from '../../assets/docker-icon';
import RunDockerTask from './run-docker-task';
import AWSEMRSparkRunTask from './aws-emr-spark-run-task';
import AWSEMRSparkIcon from '../../assets/aws-emr-spark-icon';
import LDAPTask from './ldap-commands-task'
import LdapIcon from '../../assets/ldap-icon';

const AddTask = (props:any) => {
    const [activeId , setActiveId] = useState<number>();
    const [activeInputId , setInputActiveId] = useState<number>();

    const { Panel } = Collapse;
 

    const createTask = (type:string) => {
    
        if(type === 'shell') {
            props.setDefinitionData([ ...props.definitionData , {type: "BashTask"   , id: new Date().getTime().toString() , dependsOn: [] , name: '' , command: '' , args: []}])
        } else if(type === 'python') {
            props.setDefinitionData([ ...props.definitionData , {type: "PythonTask" , id: new Date().getTime().toString() , dependsOn: [] , name: '' , script: '', venvPath:'' , args: []}])
        } else if(type === 'JavaTask') {
            props.setDefinitionData([ ...props.definitionData , {type: "JavaTask"   , id: new Date().getTime().toString() , dependsOn: [] , name: '' , jars: [] , mainClass: '', args: []}])  
        } else if(type === 'DockerRunTask') {
            props.setDefinitionData([ ...props.definitionData , {type: "DockerRunTask", id: new Date().getTime().toString() , dependsOn: [] , name: '' , dockerImage: '' , commands: [], args: []}])  
        } else if(type === 'AWSEMRSparkRunTask') {
            props.setDefinitionData([ ...props.definitionData , {type: "AWSEMRSparkRunTask"   , id: new Date().getTime().toString() , dependsOn: [] , 
            name: '' , awsAccessKey: '', awsSecretKey: '', clusterId: '', className: '', jarPath: '', args: []}])
        } else if(type === 'LDAPTask') {
            props.setDefinitionData([ ...props.definitionData , {type: "LDAPTask"   , id: new Date().getTime().toString() , dependsOn: [] , 
            name: '' , changeType: '',  ldapRemoteSystemId: '', cn: '', attributes: [] }])
        }
    
        for (let task of props.definitionData) {
            console.log(task);
        }

    }
    const menus = (
        <Menu		
        items={[
            {
                key: 'shell',
                label: <div onClick={()=> createTask('shell')  } className="tabHeader">
                    <ShellIcon />
                    <span>Shell</span>
                </div>,
            },
            {
                key: 'python',
                label: <div onClick={()=> createTask('python')} className="tabHeader">
                            <PythonIcon />
                            <span>Python</span>
                        </div>,
            },
            {
                key: 'JavaTask',
                label: <div onClick={()=> createTask('JavaTask')} className="tabHeader">
                            <JavaIcon />
                            <span>Java</span>
                        </div>,
            },
            {
                key: 'DockerRunTask',
                label: <div onClick={()=> createTask('DockerRunTask')} className="tabHeader">
                            <DockerIcon />
                            <span>Run Docker</span>
                        </div>,
            },
            {
                key: 'AWSEMRSparkRunTask',
                label: <div onClick={()=> createTask('AWSEMRSparkRunTask')} className="tabHeader">
                            <AWSEMRSparkIcon />
                            <span>AWS EMR Spark Run</span>
                        </div>,
            },
            {
                key: 'LDAPTask',
                label: <div onClick={()=> createTask('LDAPTask')} className="tabHeader">
                            <LdapIcon />
                            <span>LDAPTask</span>
                        </div>,
            }
        
        ]}
        />
    );
    
    const onActiveTaskChange = (value:any) => {
        setActiveId(value);
    }

    const onInputChange = (value:any) => {
        setInputActiveId(value);
    }


    const handleChange = (value:any, field:string) => {
        
       const newData =  props.definitionData.map((item:any) => {
            if(item.id === activeId) {
                item = {...item , [field]: value}
            }
            
            return item
        } )
        props.setDefinitionData(newData);
    }


    const handleInputChange = (value:any, field:string) => {
        
        const newData =  props.inputData.map((item:any , idx:any) => {
            //TO DO : The activeInputId is set to the index and not the actual id. 
            //Is this correct? idx is a number, activeInputId is a string.
             if(idx == activeInputId) {
                
                 item = {...item , [field]: value}
             }
             
             return item
         } )
         props.setInputData(newData);
     }

    const handleDependsOnSelectChange = (value: string | string[]) => {
        handleChange(value , 'dependsOn');
    }

    const { Option } = Select;

    const deleteTask = () => {
        const data = props.definitionData.find((item:any) => item.id === activeId);
        if(data.id) {
            props.definitionData.map((item:any) => {
               
                if(item.dependsOn.includes(data.id)) {                  
                    item.dependsOn = item.dependsOn.filter((el: string) => el != data.id)
                }
                
                return item
            })
        }
        props.setDefinitionData(props.definitionData.filter((item:any) => item.id !== activeId))
    }

    const deleteInputTask = () => {
        props.setInputData(props.inputData.filter((item:any , idx:any) => idx != activeInputId))
    }

    const addInput = () => {
        props.setInputData([ ...props.inputData , {id: new Date().getTime().toString() , name: '' , defaultValue: '' }])
    }

  
    return (
        <>
        <Button onClick={addInput} style={{marginRight: '20px'}} >Add Input</Button>

        <Dropdown  overlay={menus} placement="bottomLeft">
            <Button style={{marginBottom:'23px'}} icon={<PlusCircleOutline />}>Add Task</Button>
        </Dropdown>
        {/* This is the start of the inputs section */}
        <Row className='Workflow-Input-container' align="middle" style={{marginBottom:'20px'}}>
            {props.inputData?.length > 0 &&  (
                <Collapse accordion onChange={onInputChange} bordered >
                    {props.inputData?.map((item:any , idx:any) => {
                        if(item)
                        return (
                        <Panel  header={ <div className="tabHeader">
                        <img src={InputIcon} style={{height: '18px'}} />
                        <span>Input {item.name ? `(${item.name})` : ''}</span>
                    </div>} key={idx}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                                <label style={{marginRight: '6px'}}>Name</label>
                                <InfoCircleOutlined />
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Input placeholder="name"  value={item.name} 
                                    onChange={(ev:any) => handleInputChange(ev.target.value,'name')} style={{marginTop: '0'}} />
                            </Col>
                        </Row>      
                        
                        <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                            <Col className="gutter-row" span={6}>
                                <label style={{marginRight: '6px'}}>Default Value</label>
                                <InfoCircleOutlined />
                            </Col>
                            <Col className="gutter-row" span={10}>
                                <Input type="text" placeholder='value' value={item.defaultValue} onChange={(ev:any) => handleInputChange(ev.target.value, 'defaultValue')} />
                            </Col>
                        </Row>
                    
                        <br/>
                        <br/>
                        <DeleteButton deleteTask={deleteInputTask} />
                        
                </Panel>
                )
            })}
            {/* This is the end of the inputs section */}
        
        </Collapse>
        

        )}
    </Row>

    <Row className='Workflow-Definition-container' align="middle">
        {props?.definitionData?.length > 0 &&  (
            <Collapse accordion onChange={onActiveTaskChange} bordered >
                {props.definitionData.map((item:any) => {
                    switch(item.type) {
                        case 'JavaTask':
                            
                            return (
                            <JavaTask 
                            addInput={addInput}
                            menus={menus}
                            item={item}
                            handleDependsOnSelectChange={handleDependsOnSelectChange}
                            handleChange={handleChange}
                            deleteTask={deleteTask}
                            definitionData={props.definitionData}
                            header={
                                <div className="tabHeader">
                                    <JavaIcon /> <span> Java {item.name ? `(${item.name})` : ''}</span>
                                </div>}
                            isActive={activeId == item.id}
                            onItemClick={()=>{onActiveTaskChange(item.id)}}
                            key={item.id}
                            ></JavaTask>);
                        case 'DockerRunTask':
                            
                            return (
                            <RunDockerTask 
                            item={item}
                            handleDependsOnSelectChange={handleDependsOnSelectChange}
                            handleChange={handleChange}
                            deleteTask={deleteTask}
                            definitionData={props.definitionData}
                            header={
                                <div className="tabHeader">
                                    <DockerIcon /> <span> Run Docker {item.name ? `(${item.name})` : ''}</span>
                                </div>}
                            isActive={activeId === item.id}
                            onItemClick={()=>{onActiveTaskChange(item.id)}}
                            key={item.id}
                            />);
                        case 'AWSEMRSparkRunTask':
                            return (
                                <AWSEMRSparkRunTask 
                                item={item}
                                handleDependsOnSelectChange={handleDependsOnSelectChange}
                                handleChange={handleChange}
                                deleteTask={deleteTask}
                                definitionData={props.definitionData}
                                header={
                                    <div className="tabHeader">
                                        <AWSEMRSparkIcon /> <span> AWS EMR Spark {item.name ? `(${item.name})` : ''}</span>
                                    </div>}
                                isActive={activeId === item.id}
                                onItemClick={()=>{onActiveTaskChange(item.id)}}
                                key={item.id}
                                />);
                        case 'LDAPTask':
                            return (
                                <LDAPTask 
                                workspaceId={props.workspaceId}
                                item={item}
                                handleDependsOnSelectChange={handleDependsOnSelectChange}
                                handleChange={handleChange}
                                deleteTask={deleteTask}
                                definitionData={props.definitionData}
                                header={
                                    <div className="tabHeader">
                                        <LdapIcon /> <span> LDAP Task {item.name ? `(${item.name})` : ''}</span>
                                    </div>}
                                isActive={activeId === item.id}
                                onItemClick={()=>{onActiveTaskChange(item.id)}}
                                key={item.id}
                                />);

                        default:
                            return (<ShellPythonTask 
                            addInput={addInput}
                            menus={menus}
                            item={item}
                            handleDependsOnSelectChange={handleDependsOnSelectChange}
                            handleChange={handleChange}
                            deleteTask={deleteTask}
                            definitionData={props.definitionData}
                            header={item.type === 'BashTask' ? 
                                <div className="tabHeader">
                                    <ShellIcon /> <span>Shell {item.name ? `(${item.name})` : ''}</span>
                                </div> 
                                : 
                                <div className="tabHeader">
                                    <PythonIcon /> <span>Python {item.name ? `(${item.name})` : ''}</span>
                                </div>} 
                            isActive={activeId == item.id}
                            onItemClick={()=>{onActiveTaskChange(item.id)}}
                            key={item.id}
                            
                            ></ShellPythonTask>);
                    }
                    
                })}
        
        
        </Collapse>
        

        )}
    </Row>
    </>
    )
}

export default AddTask;